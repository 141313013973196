<template>
  <v-container fluid class="search-container pa-4 pa-sm-6 pa-md-8">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="8" xl="6">
        <!-- 搜索输入框 -->
        <v-card class="mb-6 search-card" elevation="2">
          <v-card-text>
            <v-text-field
              v-model="searchQuery"
              label="搜索条目"
              append-icon="mdi-magnify"
              @keyup.enter="searchRecords"
              @click:append="searchRecords"
              outlined
              dense
              hide-details
              class="search-input"
            ></v-text-field>
          </v-card-text>
        </v-card>

        <!-- 条目列表 -->
        <v-row>
          <v-col v-for="record in records" :key="record.id" cols="12" sm="6" md="4">
            <v-card @click="goToRecord(record.id)" class="mb-4 record-card" hover>
              <v-row no-gutters>
                <v-col cols="4">
                  <v-img :src="getImageUrl(record.title)" class="cover-image" :aspect-ratio="1" width="100"></v-img>
                </v-col>
                <v-col cols="8">
                  <v-card-title class="text-subtitle-1">{{ record.title }}</v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- 创建新条目按钮，仅登录用户可见 -->
        <v-row v-if="records.length === 0 && searchQuery.trim() && isLoggedIn && !searching">
          <v-col class="text-center">
            <p class="mb-4">没有找到相关条目。您可以创建一个新的条目。</p>
            <v-btn color="primary" @click="createNewRecord" elevation="2">
              <v-icon left>mdi-plus</v-icon>
              创建新条目
            </v-btn>
          </v-col>
        </v-row>

        <!-- 加载动画 -->
        <v-row v-if="searching">
          <v-col class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from '@/utils/request';

export default {
  name: 'Search',
  props: {
    query: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searching: false,
      searchQuery: this.query,
      records: [],
      defaultRecords: [
        {"id": 4, "title": "刘备"},
        {"id": 1, "title": "诸葛亮"},
        {"id": 3, "title": "曹操"},
        {"id": 6, "title": "关羽"},
        {"id": 5, "title": "赵云"},
        {"id": 36, "title": "张飞"},
        {"id": 39, "title": "吕布"},
        {"id": 4476, "title": "司马懿"},
        {"id": 16, "title": "孙权"},
        {"id": 26, "title": "魏延"},
        {"id": 9, "title": "姜维"},
        {"id": 2, "title": "周瑜"},
        {"id": 11, "title": "袁绍"},
        {"id": 4260, "title": "刘禅"},
        {"id": 35, "title": "马超"},
        {"id": 28, "title": "鲁肃"},
        {"id": 4424, "title": "张郃"},
        {"id": 4499, "title": "黄忠"},
        {"id": 40, "title": "邓艾"},
        {"id": 25, "title": "张辽"},
        {"id": 3685, "title": "孟获"},
        {"id": 4459, "title": "董卓"},
        {"id": 3862, "title": "刘表"},
        {"id": 4494, "title": "曹仁"},
        {"id": 4206, "title": "徐晃"},
        {"id": 4492, "title": "庞德"},
        {"id": 3785, "title": "马岱"},
        {"id": 4400, "title": "许褚"},
        {"id": 4443, "title": "孙策"},
        {"id": 4448, "title": "袁术"},
        {"id": 4276, "title": "曹真"},
        {"id": 27, "title": "甘宁"},
        {"id": 41, "title": "夏侯惇"},
        {"id": 4447, "title": "关兴"},
        {"id": 4307, "title": "司马昭"},
        {"id": 3820, "title": "刘璋"},
        {"id": 4278, "title": "陆逊"},
        {"id": 4412, "title": "庞统"},
        {"id": 4376, "title": "吕蒙"},
        {"id": 4461, "title": "于禁"},
        {"id": 4495, "title": "曹丕"},
        {"id": 4472, "title": "王平"},
        {"id": 57, "title": "孙乾"},
        {"id": 4431, "title": "夏侯渊"},
        {"id": 3896, "title": "曹洪"},
        {"id": 3509, "title": "李傕"},
        {"id": 3488, "title": "陈宫"},
        {"id": 4353, "title": "郭淮"},
        {"id": 4145, "title": "李典"},
        {"id": 4385, "title": "张鲁"},
        {"id": 4153, "title": "张翼"},
        {"id": 3826, "title": "程普"},
        {"id": 48, "title": "廖化"},
        {"id": 4487, "title": "孙坚"},
        {"id": 4196, "title": "钟会"},
        {"id": 2283, "title": "杨仪"},
        {"id": 4369, "title": "荀彧"},
        {"id": 4438, "title": "貂蝉"},
        {"id": 3884, "title": "徐庶"},
        {"id": 53, "title": "严颜"},
        {"id": 4470, "title": "关平"},
        {"id": 4208, "title": "周泰"},
        {"id": 4414, "title": "袁尚"},
        {"id": 3886, "title": "张任"},
        {"id": 10, "title": "贾诩"},
        {"id": 3827, "title": "蔡瑁"},
        {"id": 3910, "title": "韩当"},
        {"id": 51, "title": "法正"},
        {"id": 4301, "title": "韩遂"},
        {"id": 3455, "title": "郭汜"},
        {"id": 3907, "title": "徐盛"},
        {"id": 4300, "title": "太史慈"},
        {"id": 4380, "title": "文丑"},
        {"id": 3667, "title": "黄祖"},
        {"id": 4174, "title": "刘封"},
        {"id": 3219, "title": "司马师"},
        {"id": 4478, "title": "郭嘉"},
        {"id": 4488, "title": "乐进"},
        {"id": 4486, "title": "典韦"},
        {"id": 2599, "title": "董承"},
        {"id": 13, "title": "马谡"},
        {"id": 3690, "title": "审配"},
        {"id": 3674, "title": "丁奉"},
        {"id": 4493, "title": "颜良"},
        {"id": 4489, "title": "诸葛瑾"},
        {"id": 3914, "title": "曹休"},
        {"id": 56, "title": "张嶷"},
        {"id": 17, "title": "曹爽"},
        {"id": 3748, "title": "孔融"},
        {"id": 55, "title": "曹叡"},
        {"id": 4274, "title": "公孙瓒"},
        {"id": 3858, "title": "凌统"},
        {"id": 3357, "title": "邓芝"},
        {"id": 4305, "title": "程昱"},
        {"id": 50, "title": "夏侯霸"},
        {"id": 4262, "title": "刘琦"}
      ]
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  created() {
    if (this.searchQuery) {
      this.searchRecords();
    } else {
      this.records = this.defaultRecords;
    }
  },
  methods: {
    searchRecords() {
      if (this.searchQuery.trim()) {
        this.searching = true;
        service.get(`/wikiapi/records`, { params: { query: this.searchQuery } })
          .then(response => {
            this.searching = false;
            this.records = response.data.map(record => ({
              id: record.id,
              title: record.title
            }));
          })
          .catch(error => {
            console.error("Error searching records:", error);
            this.searching = false;
            this.records = [];
          });
      } else {
        this.records = this.defaultRecords;
      }
    },
    getImageUrl(title) {
      const encodedTitle = encodeURIComponent(title);
      return `https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/11/${encodedTitle}.jpg`;
    },
    goToRecord(id) {
      this.$router.push({ name: '维基条目', params: { id } });
    },
    createNewRecord() {
      this.$router.push({ name: '编辑条目', query: { title: this.searchQuery } });
    }
  }
};
</script>

<style scoped>
.search-container {
  background-color: #f5f5f5;
  min-height: calc(100vh - 100px);
}

.search-card {
  transition: box-shadow 0.3s ease;
}

.search-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.search-input >>> .v-input__slot {
  transition: all 0.3s ease;
}

.search-input >>> .v-input__slot:hover {
  background-color: #f0f0f0 !important;
}

.record-card {
  transition: all 0.3s ease;
  cursor: pointer;
}

.record-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1) !important;
}

.cover-image {
  object-fit: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.v-card__title {
  font-size: 1rem !important;
  line-height: 1.4;
  padding: 12px;
}

@media (max-width: 600px) {
  .v-card__title {
    font-size: 0.9rem !important;
    padding: 8px;
  }
}
</style>
